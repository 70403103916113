import { Box, Button, Card, CardBody, CardHeader, Center, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Textarea, useToast } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from "react";

  
import { useNavigate } from 'react-router-dom';



function ChangeCredentials() {
    const navigate = useNavigate();
    const toast = useToast()

    const [profile, setProfile] = useState({});

    const validate = (values, props) => {
        const errors = {};

        if (!values.key) {
            errors.key = 'Kredensial harus diisi';
        }

        if (!values.keySecret) {
            errors.keySecret = 'Kredensial harus diisi';
        }

        if (!values.oauthId) {
            errors.oauthId = 'Kredensial harus diisi';
        }

        if (!values.privateKey) {
            errors.privateKey = 'Kredensial harus diisi';
        }

        return errors;
    }

    const updateCredentials = async (value) => {
        const body = {
            key: value.key,
            key_secret: value.keySecret,
            oauth_id: value.oauthId,
            private_key: value.privateKey
          };
      
          const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/update-credential', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(body),
          })

          const data = await response.json();

          if(response.status === 200){
            navigate('/profile');
          }
          else{
            toast({
                title: data.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              })
          }
     };

     const getProfile = async () => {
        const response = await fetch(process.env.REACT_APP_BE_BASE_URL + `/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("token"),
          },
        })
    
        const data = await response.json();
    
        if (response.status === 200) {
          setProfile(data)
        }
        else if(response.status === 403){
          navigate('/profile');
        }
        else {
          toast({
            title: data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      };

     useEffect(() => {
        getProfile();
      }, []);

    return (
        <Center m={4}>
            <Card w='3xl' variant='outline'>
                <CardHeader>
                    <Heading size='md'>Ubah Kredensial</Heading>
                </CardHeader>

                <CardBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            key: profile.key,
                            keySecret: profile.key_secret,
                            oauthId: profile.oauth_id,
                            privateKey: profile.private_key
                          }}
                        onSubmit={async (values, actions) => {
                           await updateCredentials(values)
                        }}
                        validate={validate}
                    >
                        {(props) => (
                            <Form>
                                <Box>
                                    <Stack spacing='2'>
                                        <Field name='key'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.key && form.touched.key}>
                                                    <FormLabel>API Key</FormLabel>
                                                    <Input {...field} variant='outline' placeholder='Masukkan API Key Anda' />
                                                    <FormErrorMessage>{form.errors.key}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='keySecret'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.keySecret && form.touched.keySecret}>
                                                    <FormLabel>API Key Secret</FormLabel>
                                                    <Input {...field} variant='outline' placeholder='Masukkan API Key Secret Anda' />
                                                    <FormErrorMessage>{form.errors.keySecret}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='oauthId'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.oauthId && form.touched.oauthId}>
                                                    <FormLabel>OAuth ID</FormLabel>
                                                    <Input {...field} variant='outline' placeholder='Masukkan OAuth ID Anda' />
                                                    <FormErrorMessage>{form.errors.oauthId}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='privateKey'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.privateKey && form.touched.privateKey}>
                                                    <FormLabel>RSA Private Key</FormLabel>
                                                    <Textarea {...field} variant='outline' placeholder='Masukkan RSA private key Anda' />
                                                    <FormErrorMessage>{form.errors.privateKey}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>                                        
                                        <Button
                                            mt={4}
                                            colorScheme='blue'
                                            isLoading={props.isSubmitting}
                                            type='submit'
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </Center>
    );
}
export default ChangeCredentials;