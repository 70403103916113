import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Box, Button, Card, CardBody, CardHeader, Center, FormControl, FormErrorMessage, FormLabel, Heading, Input, InputGroup, InputRightElement, Stack, useToast } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React from "react";

  
import { useNavigate } from 'react-router-dom';



function ChangePassword() {
    const navigate = useNavigate();
    const toast = useToast()

    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    const validate = (values, props) => {
        const errors = {};

        if (!values.oldPassword) {
            errors.oldPassword = 'Password lama harus diisi';
        }

        if (!values.newPassword) {
            errors.newPassword = 'Password baru harus diisi';
        }

        if (!values.newPasswordValidate) {
            errors.newPasswordValidate = 'Password baru harus diisi';
        }

        if (values.newPassword != values.newPasswordValidate) {
            errors.newPasswordValidate = 'Password tidak sama';
        }

        return errors;
    }

    const changePassword = async (oldPassword, newPassword) => {
        const body = {
            oldpassword: oldPassword,
            newpassword: newPassword
          };
      
          const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/change-password', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(body),
          })

          const data = await response.json();

          if(response.status === 200){
            navigate('/profile');
          }
          else{
            toast({
                title: data.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              })
          }
     };

    return (
        <Center m={4}>
            <Card w='sm' variant='outline'>
                <CardHeader>
                    <Heading size='md'>Ubah Password</Heading>
                </CardHeader>

                <CardBody>
                    <Formik
                        initialValues={{
                            oldPassword: '',
                            newPassword: '',
                            newPasswordValidate: '',
                          }}
                        onSubmit={async (values, actions) => {
                           await changePassword(values.oldPassword, values.newPassword)
                        }}
                        validate={validate}
                    >
                        {(props) => (
                            <Form>
                                <Box>
                                    <Stack spacing='2'>
                                        <Field name='oldPassword'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.oldPassword && form.touched.oldPassword}>
                                                    <FormLabel>Password Lama</FormLabel>
                                                    <InputGroup size='md'>
                                                        <Input
                                                            {...field}
                                                            pr='4.5rem'
                                                            type={show ? 'text' : 'password'}
                                                            placeholder='Masukkan Password Lama Anda'
                                                            variant='outline'
                                                        />
                                                        <InputRightElement width='4.5rem'>
                                                            <Button h='1.75rem' size='sm' onClick={handleClick} variant='ghost'>
                                                                {show ? <ViewIcon /> : <ViewOffIcon />}
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    <FormErrorMessage>{form.errors.oldPassword}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='newPassword'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.newPassword && form.touched.newPassword}>
                                                    <FormLabel>Password Baru</FormLabel>
                                                    <InputGroup size='md'>
                                                        <Input
                                                            {...field}
                                                            pr='4.5rem'
                                                            type={show ? 'text' : 'password'}
                                                            placeholder='Masukkan Password Baru Anda'
                                                            variant='outline'
                                                        />
                                                        <InputRightElement width='4.5rem'>
                                                            <Button h='1.75rem' size='sm' onClick={handleClick} variant='ghost'>
                                                                {show ? <ViewIcon /> : <ViewOffIcon />}
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    <FormErrorMessage>{form.errors.newPassword}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='newPasswordValidate'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.newPasswordValidate && form.touched.newPasswordValidate}>
                                                    <FormLabel>Konfirmasi Password Baru</FormLabel>
                                                    <InputGroup size='md'>
                                                        <Input
                                                            {...field}
                                                            pr='4.5rem'
                                                            type={show ? 'text' : 'password'}
                                                            placeholder='Konfirmasi Password Baru Anda'
                                                            variant='outline'
                                                        />
                                                        <InputRightElement width='4.5rem'>
                                                            <Button h='1.75rem' size='sm' onClick={handleClick} variant='ghost'>
                                                                {show ? <ViewIcon /> : <ViewOffIcon />}
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    <FormErrorMessage>{form.errors.newPasswordValidate}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Button
                                            mt={4}
                                            colorScheme='blue'
                                            isLoading={props.isSubmitting}
                                            type='submit'
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </Center>
    );
}
export default ChangePassword;