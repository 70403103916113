import {
  useToast
} from '@chakra-ui/react';
import React from "react";

  
import { useNavigate } from 'react-router-dom';



function Logout() {
    const navigate = useNavigate();
    const toast = useToast()

    const logout = async () => {      
          const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/logout', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + localStorage.getItem("token"),
            },
          })

          const data = await response.json();

          if(response.status === 200){
            localStorage.removeItem('token');

            navigate('/login');
          }
          else{
            toast({
                title: data.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              })
          }
     };

     logout();


    return (
        <></>
    );
}
export default Logout;