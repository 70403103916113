import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, Heading, Input, Spacer, Text, useToast, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";

import { useNavigate } from 'react-router-dom';

import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from "@ajna/pagination";


function Users() {
  const navigate = useNavigate();
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState({})
  const [APIData, setAPIData] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  const [totalUser, setTotalUser] = useState(undefined);

  const [usernameFilter, setUsernameFilter] = useState('')
  const [filter, setFilter] = useState({
    'username':'',
    'name':'',
    'nip':''
  })
  const [filterParam, setFilterParam] = useState({
    'username':'',
    'name':'',
    'nip':''
  })
  const handleChange = (event) => {
    setUsernameFilter(event.target.value)
    filter[event.target.id] = event.target.value
    setFilter(filter)
  }

  const handleFilter = (event) => {
    setCurrentPage(1)
    setFilterParam({...filter})
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    pages,
    pagesCount,
    offset,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalUser,
    limits: {
      outer: 10,
      inner: 1,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  });


  const getUsers = async () => {
    const response = await fetch(process.env.REACT_APP_BE_BASE_URL + `/users?page=${currentPage}&username=${filterParam.username}&name=${filterParam.name}&nip=${filterParam.nip}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    })

    const data = await response.json();

    if (response.status === 200) {
      setAPIData(data.data)
      setTotalUser(data.total)
    }
    else if (response.status === 403) {
      navigate('/profile');
    }
    else {
      toast({
        title: data.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  };



  const handleUserStatus = (user) => {
    setUser(user)
    onOpen()
  }

  const updateUserStatus = (user) => {


    const updateUser = async () => {
      setIsLoading(true)
      const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/user/' + user.id + '/' + (user.status == 'active' ? 'inactive' : 'active'), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token"),
        },
      })

      const data = await response.json();

      if (response.status === 200) {
        // setAPIData(data)
        setTriggerUpdate(!triggerUpdate)
      }
      else if (response.status === 403) {
        navigate('/profile');
      }
      else {
        toast({
          title: data.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
      setIsLoading(false)
      onClose()
    };
    updateUser();
  }
  const handlePageChange = (nextPage) => {
    // -> request new data using the page number
    setCurrentPage(nextPage);
  };

  useEffect(() => {
    getUsers()
    // setIsUpdated(false)
  }, [triggerUpdate, currentPage, filterParam]);

  return (
    <Center m={5}>
      <Box w='75%' >
        <Flex p={4}>
          <Heading as='h2' size='lg'>Daftar User</Heading>
          <Spacer />

          <Button size='md' colorScheme='blue' maxW={150} onClick={() => { navigate('/add-user'); }}>Tambah User</Button>

        </Flex>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
            <Tr>
                <Th>
                  <Input id='username' value={filter.username} onChange={handleChange} placeholder='Cari User AD' size='sm' />
                </Th>
                <Th>
                  <Input id='name' value={filter.name} onChange={handleChange} placeholder='Cari nama user' size='sm' />
                </Th>
                <Th>
                  <Input id='nip' value={filter.nip} onChange={handleChange} placeholder='Cari NIP user' size='sm' />
                </Th>
                <Th></Th>
                <Th></Th>
                <Th><Center><Button colorScheme='blue' size='sm' onClick={handleFilter} variant='outline'>Cari</Button></Center></Th>
              </Tr>
              <Tr>
                <Th><Center>User AD</Center></Th>
                <Th><Center>Nama</Center></Th>
                <Th><Center>NIP</Center></Th>
                <Th><Center>Role</Center></Th>
                <Th><Center>Status</Center></Th>
                <Th></Th>
              </Tr>
              
            </Thead>
            <Tbody>
              {APIData.map((data) => {
                return (
                  <Tr>
                    <Td>{data.username}</Td>
                    <Td>{data.name}</Td>
                    <Td><Center>{data.nip}</Center></Td>
                    <Td><Center>{data.role == 'admin' ? 'Admin' : data.role == 'audit' ? 'Audit' : 'General'}</Center></Td>
                    <Td><Center>{data.status == 'active' ? 'Aktif' : 'Nonaktif'}</Center></Td>
                    <Td><Center>
                      <Button colorScheme={data.status == 'active' ? 'red' : 'blue'} variant='link' onClick={() => handleUserStatus(data)}>
                        {data.status == 'active' ? 'Nonaktifkan' : 'Aktifkan'}
                      </Button>
                    </Center></Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          //   isDisabled={isDisabled}
          onPageChange={handlePageChange}
        >
          <PaginationContainer
            align="center"
            justify="space-between"
            p={4}
            w="full"
          >
            <PaginationPrevious
              variant="ghost"
              colorScheme="blue"
            >
              <ChevronLeftIcon />
            </PaginationPrevious>
            <PaginationPageGroup
              isInline
              align="center"
              separator={
                <PaginationSeparator
                  variant="ghost"
                  colorScheme="blue"
                  fontSize="sm"
                  w={7}
                  jumpSize={11}
                />
              }
            >
              {pages.map((page) => (
                <PaginationPage
                  w={7}
                  variant="ghost"
                  colorScheme="blue"
                  key={`pagination_page_${page}`}
                  page={page}
                  fontSize="sm"
                  _current={{
                    bg: "blue.500",
                    fontSize: "sm",
                    color: "white",
                    w: 7,
                  }}
                />
              ))}
            </PaginationPageGroup>
            <PaginationNext
              variant="ghost"
              colorScheme="blue"
            >
              <ChevronRightIcon />
            </PaginationNext>
          </PaginationContainer>
        </Pagination>
      </Box>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text align='center'>
              Apakah Anda yakin ingin {user.status == 'active' ? 'menonaktifkan' : 'mengaktifkan'} user tersebut
            </Text>
          </ModalBody>
          <Center>
            <ModalFooter>

              <Button colorScheme='red' mr={3} onClick={onClose}>
                Batal
              </Button>
              <Button colorScheme='blue' onClick={() => updateUserStatus(user)} isLoading={isLoading}>
                Ya
              </Button>
            </ModalFooter>
          </Center>
        </ModalContent>
      </Modal>

    </Center>
  );
}
export default Users;