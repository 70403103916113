import { Box, Center, Heading, Stack, Text, useToast, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Flex, Spacer } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';



function APIDetail() {
  const { id } = useParams()
  const navigate = useNavigate();
  const toast = useToast()

  const [requestBody, setRequestBody] = useState('')
  const [responseBody, setResponseBody] = useState('')
  const [requestHeader, setRequestHeader] = useState({})
  const [responseHeader, setResponseHeader] = useState({})

  const [APIData, setAPIData] = useState([]);
  const getAPIDetail = async () => {
    const response = await fetch(process.env.REACT_APP_BE_BASE_URL + `/api-log/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    })

    const data = await response.json();

    if (response.status === 200) {
      setAPIData(data)
      setRequestBody(JSON.stringify(JSON.parse(data.request_body), null, 4))
      setResponseBody(JSON.stringify(JSON.parse(data.response_body), null, 4))
      setRequestHeader(JSON.parse(data.request_header))
      setResponseHeader(JSON.parse(data.response_header))
    }
    else if (response.status === 403) {
      navigate('/profile');
    }
    else {
      toast({
        title: data.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  };

  useEffect(() => {
    getAPIDetail()
  }, []);

  return (
    <Center m={5}>
      <Box w='75%' p={4} >
        <Stack spacing={3}>
          <Heading as='h2' size='lg' mb={3}>
            Detail Log
          </Heading>
          <Heading as='h4' size='md'>
            User AD
          </Heading>
          <Text fontSize='md'>{APIData.user?.username}</Text>
          <Heading as='h4' size='md'>
            Endpoint
          </Heading>
          <Text fontSize='md'>{APIData.endpoint}</Text>
          <Heading as='h4' size='md'>
            Timestamp
          </Heading>
          <Text fontSize='md'>{APIData.timestamp}</Text>
          <Flex>
            <Box w='50%' maxWidth='50%' pr={3}>
              <Stack spacing={3}>
                <Heading as='h4' size='md'>
                  Request
                </Heading>
                <Tabs size='md' variant='enclosed'>
                  <TabList>
                    <Tab>Body</Tab>
                    <Tab>Header</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Textarea type="text" whiteSpace="pre" value={requestBody} isReadOnly h={64} />
                    </TabPanel>
                    <TabPanel>
                      <TableContainer>
                        <Table variant='simple'>
                          <Thead>
                            <Tr>
                              <Th>Key</Th>
                              <Th>Value</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {Object.keys(requestHeader).map((data) => {
                              return (
                                <Tr>
                                  <Td>{data}</Td>
                                  <Td>{requestHeader[data]}</Td>
                                </Tr>
                              )
                            })}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Stack>
            </Box>
            <Spacer />
            <Box w='50%' maxWidth='50%' pl={3}>
              <Stack spacing={3}>
                <Heading as='h4' size='md'>
                  Response
                </Heading>
                <Tabs size='md' variant='enclosed'>
                  <TabList>
                    <Tab>Body</Tab>
                    <Tab>Header</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Textarea type="text" whiteSpace="pre" value={responseBody} isReadOnly h={64} />
                    </TabPanel>
                    <TabPanel>
                      <TableContainer>
                        <Table variant='simple'>
                          <Thead>
                            <Tr>
                              <Th>Key</Th>
                              <Th>Value</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {Object.keys(responseHeader).map((data) => {
                              return (
                                <Tr>
                                  <Td>{data}</Td>
                                  <Td>{responseHeader[data]}</Td>
                                </Tr>
                              )
                            })}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Stack>
            </Box>
          </Flex>
        </Stack>

      </Box>

    </Center>
  );
}
export default APIDetail;