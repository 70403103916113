import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, HStack, Heading, Input, useToast, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";

import { useNavigate } from 'react-router-dom';

import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from "@ajna/pagination";

import { ConfigProvider, DatePicker } from "antd";
const { RangePicker } = DatePicker;



function APILog() {
  const navigate = useNavigate();
  const toast = useToast()

  const [APIData, setAPIData] = useState([]);

  const [dataCount, setDataCount] = useState(undefined);


  const {
    pages,
    pagesCount,
    offset,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize
  } = usePagination({
    total: dataCount,
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1
    }
  });


  const getAPILogs = async () => {
    const response = await fetch(process.env.REACT_APP_BE_BASE_URL + `/api-log?page=${currentPage}&username=${filterParam.username}&endpoint=${filterParam.endpoint}&timestamp_from=${filterParam.timestamp_from}&timestamp_to=${filterParam.timestamp_to}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    })

    const data = await response.json();

    if (response.status === 200) {
      setAPIData(data.data)
      setDataCount(data.total)
    }
    else if (response.status === 403) {
      navigate('/profile');
    }
    else {
      toast({
        title: data.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  };

  const handlePageChange = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const [usernameFilter, setUsernameFilter] = useState('')
  const [filter, setFilter] = useState({
    'username': '',
    'endpoint': '',
  })
  const [filterParam, setFilterParam] = useState({
    'username': '',
    'endpoint': '',
    'timestamp_from': '',
    'timestamp_to': '',
  })

  const onChangeFilter = (event) => {
    setUsernameFilter(event.target.value)
    filter[event.target.id] = event.target.value
    setFilter(filter)
  }

  const handleFilter = (event) => {
    setCurrentPage(1)
    setFilterParam({ ...filter })
  }

  function onChangeTimestampFilter(date, dateString) {
    filter['timestamp_from'] = dateString[0] == null ? '' : dateString[0]
    filter['timestamp_to'] = dateString[1] == null ? '' : dateString[1]
    setFilter(filter)
  }

  useEffect(() => {
    getAPILogs()
  }, [currentPage, filterParam]);



  return (
    <Center m={5}>
      <Box w='75%' >
        <Flex p={4}>
          <Heading as='h2' size='lg'>Log API Call</Heading>
        </Flex>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th><Center>User AD</Center></Th>
                <Th><Center>Endpoint</Center></Th>
                <Th><Center>Timestamp</Center></Th>
                <Th><Center>Status</Center></Th>
                <Th></Th>
              </Tr>
              <Tr>
                <Th>
                  <Input id='username' value={filter.username} onChange={onChangeFilter} placeholder='Cari User AD' size='sm' />
                </Th>
                <Th>
                  <Input id='endpoint' value={filter.endpoint} onChange={onChangeFilter} placeholder='Cari endpoint' size='sm' />
                </Th>
                <Th>
                  <Center>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: '#3182CE',
                        },
                      }}
                    >
                      <RangePicker activeBorderColor='#ff4d4f' onChange={onChangeTimestampFilter} placeholder={['Dari', 'Sampai']} />
                    </ConfigProvider>
                  </Center>
                </Th>
                <Th></Th>
                <Th><Center><Button colorScheme='blue' size='sm' onClick={handleFilter} variant='outline'>Cari</Button></Center></Th>
              </Tr>
            </Thead>
            <Tbody>
              {APIData.map((data) => {
                return (
                  <Tr>
                    <Td>{data.user.username}</Td>
                    <Td>{data.endpoint}</Td>
                    <Td><Center>{data.timestamp}</Center></Td>
                    {data.response_body.includes("\"responseCode\":\"200")
                      ? <Td color='blue.500'><Center>Success</Center></Td>
                      : <Td color='red.500'><Center>Failed</Center></Td>
                    }
                    <Td><Center>
                      <Button colorScheme='blue' variant='link' onClick={() => navigate(`/api-log/${data.id}`)}>
                        {'Detail'}
                      </Button>
                    </Center></Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          //   isDisabled={isDisabled}
          onPageChange={handlePageChange}
        >
          <PaginationContainer
            align="center"
            justify="space-between"
            p={4}
            w="full"
          >
            <PaginationPrevious
              variant="ghost"
              colorScheme="blue"
            >
              <ChevronLeftIcon />
            </PaginationPrevious>
            <PaginationPageGroup
              isInline
              align="center"
              separator={
                <PaginationSeparator
                  variant="ghost"
                  colorScheme="blue"
                  fontSize="sm"
                  w={7}
                  jumpSize={11}
                />
              }
            >
              {pages.map((page) => (
                <PaginationPage
                  w={7}
                  variant="ghost"
                  colorScheme="blue"
                  key={`pagination_page_${page}`}
                  page={page}
                  fontSize="sm"
                  _current={{
                    bg: "blue.500",
                    fontSize: "sm",
                    color: "white",
                    w: 7,
                  }}
                />
              ))}
            </PaginationPageGroup>
            <PaginationNext
              variant="ghost"
              colorScheme="blue"
            >
              <ChevronRightIcon />
            </PaginationNext>
          </PaginationContainer>
        </Pagination>
      </Box>
    </Center>
  );
}
export default APILog;