import { Box, Button, Center, Heading, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useState, useEffect } from "react";


import { useNavigate } from 'react-router-dom';


function Profile() {
  const navigate = useNavigate();
  const toast = useToast()

  const [user, setUser] = useState({})

  const getProfile = async () => {
    const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/profile', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    })

    const data = await response.json();

    if (response.status === 200) {
      setUser(data)
    }
    else if (response.status === 401) {
      navigate('/login');
    }
    else {
      toast({
        title: data.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  };

  useEffect(() => {
    getProfile();
  }, []);



  return (
    <Center m={5}>
      <Box w='75%' p={4} >
        <Stack spacing={3}>
          <Heading as='h2' size='lg' mb={3}>
            Profil
          </Heading>
          <Heading as='h4' size='md'>
            User AD
          </Heading>
          <Text fontSize='md'>{user.username}</Text>
          <Heading as='h4' size='md'>
            Nama
          </Heading>
          <Text fontSize='md'>{user.name}</Text>
          <Heading as='h4' size='md'>
            NIP
          </Heading>
          <Text fontSize='md'>{user.nip}</Text>
          <Heading as='h4' size='md'>
            Role
          </Heading>
          <Text fontSize='md'>{user.role}</Text>
          <Button colorScheme='blue' maxW={150} onClick={() => { navigate('/change-password'); }}>Ubah Password</Button>
          {user.role == 'general' &&
            <>

              <Heading as='h2' size='lg' mb={3} mt={3}>
                Kredensial
              </Heading>
              <Heading as='h4' size='md'>
                API Key
              </Heading>
              <Text fontSize='md'>{user.key}</Text>
              <Heading as='h4' size='md'>
                API Key Secret
              </Heading>
              <Text fontSize='md'>{user.key_secret}</Text>
              <Heading as='h4' size='md'>
                OAuth ID
              </Heading>
              <Text fontSize='md'>{user.oauth_id}</Text>
              <Heading as='h4' size='md'>
                RSA Private Key
              </Heading>
              <Text fontSize='md' whiteSpace="pre">{user.private_key}</Text>
              <Button colorScheme='blue' maxW={150} onClick={() => { navigate('/change-credentials'); }}>Edit</Button>
            </>
          }
        </Stack>
      </Box>

    </Center>
  );
}
export default Profile;