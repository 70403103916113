import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Box, Button, Card, CardBody, CardHeader, Center, FormControl, FormErrorMessage, FormLabel, Heading, Input, InputGroup, InputRightElement, Stack, useToast } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React from "react";

import { useNavigate } from 'react-router-dom';



function Login() {
    const navigate = useNavigate();
    const toast = useToast()



    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)

    const validate = (values, props) => {
        const errors = {};

        if (!values.username) {
            errors.username = 'User AD harus diisi';
        }

        if (!values.password) {
            errors.password = 'Password harus diisi';
        }

        return errors;
    }

    const login = async (username, password) => {
        const user = {
            username: username,
            password: password
          };
      
          const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/login', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
            body: JSON.stringify(user),
          })

          const data = await response.json();

          if(response.status === 200){
            localStorage.setItem('token', data.plainTextToken);
            navigate('/profile');
          }
          else{
            toast({
                title: data.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              })
          }
     };

    return (
        <Center m={4}>
            <Card w='sm' variant='outline'>
                <CardHeader>
                    <Heading size='md'>Masuk</Heading>
                </CardHeader>

                <CardBody>
                    <Formik
                        initialValues={{
                            username: '',
                            password: '',
                          }}
                        onSubmit={async (values, actions) => {
                           await login(values.username, values.password)
                        }}
                        validate={validate}
                    >
                        {(props) => (
                            <Form>
                                <Box>
                                    <Stack spacing='2'>
                                        <Field name='username'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.username && form.touched.username}>
                                                    <FormLabel>User AD</FormLabel>
                                                    <Input {...field} variant='outline' placeholder='Masukkan User AD Anda' />
                                                    <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='password'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.password && form.touched.password}>
                                                    <FormLabel>Password</FormLabel>
                                                    <InputGroup size='md'>
                                                        <Input
                                                            {...field}
                                                            pr='4.5rem'
                                                            type={show ? 'text' : 'password'}
                                                            placeholder='Masukkan Password Anda'
                                                            variant='outline'
                                                        />
                                                        <InputRightElement width='4.5rem'>
                                                            <Button h='1.75rem' size='sm' onClick={handleClick} variant='ghost'>
                                                                {show ? <ViewIcon /> : <ViewOffIcon />}
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Button
                                            mt={4}
                                            colorScheme='blue'
                                            isLoading={props.isSubmitting}
                                            type='submit'
                                        >
                                            Masuk
                                        </Button>
                                    </Stack>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </Center>
    );
}
export default Login;