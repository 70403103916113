import { Box, Button, Center, FormControl, FormErrorMessage, Heading, Input, Stack, useToast, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Flex, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from "react";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'



function APITools() {
  const toast = useToast()

  const [responseBody, setResponseBody] = useState('')
  const [requestHeader, setRequestHeader] = useState({})
  const [responseHeader, setResponseHeader] = useState({})
  const [stringToSign, setStringToSign] = useState({})

  const validate = (values, props) => {
    const errors = {};

    if (!values.endpoint) {
      errors.endpoint = 'URL harus diisi';
    }

    if (!values.requestBody) {
      errors.requestBody = 'Request body harus diisi';
    }
    else {
      try {
        JSON.parse(values.requestBody);
      } catch (error) {
        errors.requestBody = 'Format request body harus berupa JSON';
      }
    }

    return errors;
  }

  const callAPI = async (value) => {
    resetValues();

    const body = {
      method: 'POST',
      endpoint: value.endpoint,
      body: JSON.stringify(JSON.parse(value.requestBody)),
      tanpaToken: value.tanpaToken,
    };

    const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/api-test', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    })

    const data = await response.json();

    if (response.status === 200) {
      setAPIData(data)
      // setRequestBody(JSON.stringify(JSON.parse(data.request_body), null, 4))
      setResponseBody(JSON.stringify(JSON.parse(data.response_body), null, 4))
      setRequestHeader(JSON.parse(data.request_header))
      setResponseHeader(JSON.parse(data.response_header))
      setStringToSign(data.stringToSign)

    }
    else {
      toast({
        title: data.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  };

  const [APIData, setAPIData] = useState([]);

  const resetValues = () => {
    setResponseBody('')
    setRequestHeader({})
    setResponseHeader({})
  }

  return (
    <Box>
      <Formik
        initialValues={{
          endpoint: '',
          requestBody: '',
          tanpaToken: false,
        }}
        onSubmit={async (values, actions) => {
          await callAPI(values)
        }}
        validate={validate}
      >
        {(props) => (
          <Form>
            <Center>
              <Box w='75%' p={4} >
                <Stack spacing={3}>
                  <Heading as='h2' size='lg' mb={3}>
                    API Tools
                  </Heading>

                  <Flex>
                    <Field name='endpoint'>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.endpoint && form.touched.endpoint}>
                          <Input {...field} placeholder='Masukkan URL' />

                          <FormErrorMessage>{form.errors.endpoint}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Spacer />
                    <Button ml={4} size='md' colorScheme='blue' maxW={150} isLoading={props.isSubmitting} type='submit'>Kirim</Button>
                  </Flex>
                  <Field name='tanpaToken'>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.tanpaToken && form.touched.tanpaToken}>
                        <Checkbox {...field}>Tanpa token</Checkbox>
                      </FormControl>
                    )}
                  </Field>
                  <Flex>
                    <Box w='50%' maxWidth='50%' pr={3}>
                      <Stack spacing={3}>
                        <Heading as='h4' size='md'>
                          Request
                        </Heading>
                        <Tabs size='md' variant='enclosed'>
                          <TabList>
                            <Tab>Body</Tab>
                            <Tab>Header</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>

                              <Field name='requestBody'>
                                {({ field, form }) => (
                                  <FormControl isInvalid={form.errors.requestBody && form.touched.requestBody}>
                                    <Textarea {...field} type="text" whiteSpace="pre" h={64} placeholder='Masukkan request body' />
                                    <FormErrorMessage>{form.errors.requestBody}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            </TabPanel>
                            <TabPanel>
                              <TableContainer>
                                <Table variant='simple'>
                                  <Thead>
                                    <Tr>
                                      <Th>Key</Th>
                                      <Th>Value</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {Object.keys(requestHeader).map((data) => {
                                      return (
                                        <Tr>
                                          <Td>{data}</Td>
                                          <Td>{requestHeader[data]}</Td>
                                        </Tr>
                                      )
                                    })}
                                    <Tr>
                                      <Td>stringToSign</Td>
                                      <Td>{JSON.stringify(stringToSign)}</Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Stack>
                    </Box>
                    <Spacer />
                    <Box w='50%' maxWidth='50%' pl={3}>
                      <Stack spacing={3}>
                        <Heading as='h4' size='md'>
                          Response
                        </Heading>
                        <Tabs size='md' variant='enclosed'>
                          <TabList>
                            <Tab>Body</Tab>
                            <Tab>Header</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>
                              <Textarea type="text" whiteSpace="pre" value={responseBody} isReadOnly h={64} />
                            </TabPanel>
                            <TabPanel>
                              <TableContainer>
                                <Table variant='simple'>
                                  <Thead>
                                    <Tr>
                                      <Th>Key</Th>
                                      <Th>Value</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {Object.keys(responseHeader).map((data) => {
                                      return (
                                        <Tr>
                                          <Td>{data}</Td>
                                          <Td>{responseHeader[data]}</Td>
                                        </Tr>
                                      )
                                    })}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Stack>
                    </Box>
                  </Flex>
                </Stack>

              </Box>
            </Center>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
export default APITools;