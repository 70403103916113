import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import './App.css';
import Navbar from "./components/navbar";
import NotFound from "./components/not-found";
import AddUser from "./pages/add-user";
import APIDetail from "./pages/api-detail";
import History from "./pages/api-history";
import HistoryDetail from "./pages/api-history-detail";
import APILog from "./pages/api-log";
import APITools from "./pages/api-tools";
import ChangeCredentials from "./pages/change-credentials";
import ChangePassword from "./pages/change-password";
import Login from "./pages/login";
import Logout from "./pages/logout";
import Profile from "./pages/profile";
import Users from "./pages/users";


import PrivateRoute from './routes/privateRoute';




function App() {
  return (
    <ChakraProvider>
      <Router>
            <Navbar />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<PrivateRoute Component={Profile} />} />
              <Route path="/change-password" element={<PrivateRoute Component={ChangePassword} />} />
              <Route path="/change-credentials" element={<PrivateRoute Component={ChangeCredentials} />} />
              <Route path="/users" element={<PrivateRoute Component={Users} />} />
              <Route path="/add-user" element={<PrivateRoute Component={AddUser} />} />
              <Route path="/api-log" element={<PrivateRoute Component={APILog} />} />
              <Route path="/api-log/:id" element={<PrivateRoute Component={APIDetail} />} />
              <Route path="/api-tools" element={<PrivateRoute Component={APITools} />} />
              <Route path="/history" element={<PrivateRoute Component={History} />} />
              <Route path="/history/:id" element={<PrivateRoute Component={HistoryDetail} />} />
              <Route path="/logout" element={<PrivateRoute Component={Logout} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    </ChakraProvider>
  )
}

export default App;
