import { Navigate } from "react-router-dom";
import { useState } from "react";

const PrivateRoute = ({ Component }) => {
 
const [isAuthenticated, setIsAuthenticated] = useState(false);

  const token = localStorage.getItem("token")
 
  return token != null ? <Component /> : <Navigate to="/login" />;
};
export default PrivateRoute;