import React from "react";
import { Navigate, useNavigate } from "react-router-dom";




function NotFound() {
    const navigate = useNavigate();
    navigate('/profile');
    
    return <Navigate to="/profile" />;

}
export default NotFound;