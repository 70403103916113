import { Box, Button, ButtonGroup, Flex, Image, Spacer } from '@chakra-ui/react';
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import btnLogo from "../assets/images/api_tools_logo.png";



const Navbar = () => {
	const navigate = useNavigate();
	const [role, setRole] = useState('');

	const token = localStorage.getItem("token")


	const getProfile = async () => {
		const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/profile', {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("token"),
			},
		})

		const data = await response.json();

		if (response.status === 200) {
			setRole(data.role)
		}
		else {
			setRole('')
		}
	};

	if (token != null) {
		getProfile();
	}


	return (
		<Box w='100%' p={4} border='1px' borderColor='gray.200'>
			<Flex minWidth='max-content' alignItems='center' gap='2'>
				<Box p='2'>
					<Image src={btnLogo} alt='Bank BTN' htmlWidth='200px' />
				</Box>
				<Spacer />
				<ButtonGroup gap='2' mx={5}>
					{role == 'superadmin' &&
						<>
							<Button colorScheme='blue' variant='link' onClick={() => { navigate('/users'); }}>User Management</Button>
						</>
					}
					{role == 'audit' &&
						<>
							<Button colorScheme='blue' variant='link' onClick={() => { navigate('/api-log'); }}>Log API</Button>
						</>
					}
					{role == 'general' &&
						<>
							<Button colorScheme='blue' variant='link' onClick={() => { navigate('/api-tools'); }}>API Tools</Button>
							<Button colorScheme='blue' variant='link' onClick={() => { navigate('/history'); }}>History</Button>
						</>
					}
					{role != '' &&
						<>
							<Button colorScheme='blue' variant='link' onClick={() => { navigate('/profil'); }}>Profil</Button>
							<Button colorScheme='red' variant='link' onClick={() => { navigate('/logout'); }}>Log Out</Button>
						</>
					}
					{role == '' &&
						<>
							<Button colorScheme='blue' variant='link' onClick={() => { navigate('/login'); }}>Log In</Button>
						</>
					}
				</ButtonGroup>
			</Flex>
		</Box>

	);
};

export default Navbar;
