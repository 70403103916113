import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Box, Button, Card, CardBody, CardHeader, Center, FormControl, FormErrorMessage, FormLabel, Heading, Input, InputGroup, InputRightElement, Select, Stack, useToast } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import React, { useState } from "react";

  
import { useNavigate } from 'react-router-dom';



function AddUser() {
    const navigate = useNavigate();
    const toast = useToast()



    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)


    const validate = (values, props) => {
        const errors = {};

        if (!values.username) {
            errors.username = 'User AD harus diisi';
        }
        else if (values.username.length < 5) {
            errors.username = 'Panjang User AD minimal 5 karakter';
        }

        if (!values.name) {
            errors.name = 'Nama user harus diisi';
        }

        if (!values.nip) {
            errors.nip = 'NIP user harus diisi';
        }
        else if (values.nip.length != 5) {
            errors.nip = 'NIP user harus 5 digit';
        }

        if (!values.role) {
            errors.role = 'Role user harus dipilih';
        }

        return errors;
    }

    const addUser = async (values, setFieldError) => {
        const user = {
            username: values.username,
            name: values.name,
            nip: values.nip,
            role: values.role,
          };
      
          const response = await fetch(process.env.REACT_APP_BE_BASE_URL + '/user', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(user),
          })

          const data = await response.json();

          if(response.status === 200){
            navigate('/users');
          }
          else if(response.status === 403){
            navigate('/profile');
          }
          else if(response.status === 422){
            if(data.errors.username != null){
                toast({
                    title: data.errors.username[0],
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                  })
            }
            if(data.errors.nip != null){
                toast({
                    title: data.errors.nip[0],
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                  })
            }
          }
          else{
            toast({
                title: data.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
              })
          }
     };

    return (
        <Center m={4}>
            <Card w='sm' variant='outline'>
                <CardHeader>
                    <Heading size='md'>Tambah User</Heading>
                </CardHeader>

                <CardBody>
                    <Formik
                        initialValues={{
                            username: '',
                            password: process.env.REACT_APP_DEFAULT_USER_PASS,
                          }}
                        onSubmit={async (values, actions, setFieldError) => {
                           await addUser(values, setFieldError)
                        }}
                        validate={validate}
                    >
                        {(props) => (
                            <Form>
                                <Box>
                                    <Stack spacing='2'>
                                        <Field name='username'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.username && form.touched.username}>
                                                    <FormLabel>User AD</FormLabel>
                                                    <Input {...field} variant='outline' placeholder='Masukkan User AD' />
                                                    <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='name'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.name && form.touched.name}>
                                                    <FormLabel>Nama</FormLabel>
                                                    <Input {...field} variant='outline' placeholder='Masukkan nama user' />
                                                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='nip'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.nip && form.touched.nip}>
                                                    <FormLabel>NIP</FormLabel>
                                                    <Input {...field} variant='outline' placeholder='Masukkan NIP user' />
                                                    <FormErrorMessage>{form.errors.nip}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='role'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.role && form.touched.role}>
                                                    <FormLabel>Role</FormLabel>
                                                    <Select {...field} variant='outline' placeholder='Pilih role user'>
                                                        <option value='audit'>Audit</option>
                                                        <option value='general'>General</option>
                                                    </Select>
                                                    <FormErrorMessage>{form.errors.role}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='password'>
                                            {({ field, form }) => (
                                                <FormControl isInvalid={form.errors.password && form.touched.password}>
                                                    <FormLabel>Password</FormLabel>
                                                    <InputGroup size='md'>
                                                        <Input
                                                            {...field}
                                                            pr='4.5rem'
                                                            type={show ? 'text' : 'password'}
                                                            placeholder='Masukkan Password Anda'
                                                            variant='filled'
                                                            isDisabled
                                                        />
                                                        <InputRightElement width='4.5rem'>
                                                            <Button h='1.75rem' size='sm' onClick={handleClick} variant='ghost'>
                                                                {show ? <ViewIcon /> : <ViewOffIcon />}
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Button
                                            mt={4}
                                            colorScheme='blue'
                                            isLoading={props.isSubmitting}
                                            type='submit'
                                        >
                                            Kirim
                                        </Button>
                                    </Stack>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </Center>
    );
}
export default AddUser;